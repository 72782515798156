/* reset */
/* main variables */
body { margin: 0px; padding: 0px; font-family: Roboto, arial, sans-serif; background: #fff; height: 100%; font-size: 18px !important; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; color: rgba(0, 17, 24, 0.6); line-height: 21px; }

html { font-size: 10px !important; }

/*MAIN*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; font-size: 100%; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main { display: block; }

blockquote, q { quotes: none; }

blockquote:before, blockquote:after, q:before, q:after { content: ''; content: none; }

table { border-collapse: collapse; border-width: 0px; padding: 0px; margin: 0px; }

html { height: 100%; -webkit-text-size-adjust: none; -ms-text-size-adjust: none; }

input, textarea, select, button { font-family: Roboto, arial, sans-serif; }

input, textarea { color: #001118; font-family: Roboto, arial, sans-serif; outline: none; border-radius: 0; -moz-border-radius: 0; -webkit-border-radius: 0; -webkit-appearance: none; }

textarea { overflow: auto; }

input[type="button"], input[type="submit"], button { cursor: pointer; }

td { margin: 0px; padding: 0px; }

form { padding: 0px; margin: 0px; }

a { color: #30B1E9; -webkit-transition: all 0.3s ease-in; -moz-transition: all 0.3s ease-in; -ms-transition: all 0.3s ease-in; -o-transition: all 0.3s ease-in; transition: all 0.3s ease-in; text-decoration: none; outline: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

a:hover { color: #B4DA4D; }

a, span, div, button { outline: none !important; }

input[type=submit], input[type=button], button { -webkit-appearance: none; outline: none; }

* { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); outline: none; }

*:before, *:after { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

strong { font-weight: 700; }

.clearfix:after, .wrapper:after, .row:after { content: "."; display: block; height: 0; clear: both; visibility: hidden; }

img { max-width: 100%; }

@media screen and (max-width: 1023px) { body { font-size: 16px !important; } }

@media screen and (max-width: 767px) { body { font-size: 14px !important; } }

/*@media screen and (max-width: 1400px)  and (min-width: 1025px){
html {
	font-size: 9px !important;
}
}*/
@font-face { font-family: 'Roboto'; src: url("../fonts/Roboto-Regular/Roboto-Regular.eot"); src: url("../fonts/Roboto-Regular/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Regular/Roboto-Regular.woff") format("woff"), url("../fonts/Roboto-Regular/Roboto-Regular.ttf") format("truetype"), url("../fonts/Roboto-Regular/Roboto-Regular.svg#Roboto-Regular") format("svg"); font-weight: 400; font-style: normal; font-stretch: normal; font-display: swap; }

@font-face { font-family: 'Roboto'; src: url("../fonts/Roboto-Regular-Italic/RobotoItalic.eot"); src: url("../fonts//Roboto-Regular-Italic/RobotoItalic.eot?#iefix") format("embedded-opentype"), url("../fonts//Roboto-Regular-Italic/RobotoItalic.woff") format("woff"), url("../fonts//Roboto-Regular-Italic/RobotoItalic.ttf") format("truetype"), url("../fonts//Roboto-Regular-Italic/RobotoItalic.svg#RobotoItalic") format("svg"); font-weight: 400; font-style: italic; font-stretch: normal; font-display: swap; }

@font-face { font-family: 'Roboto'; src: url("../fonts/Roboto-Medium/Roboto-Medium.eot"); src: url("../fonts/Roboto-Medium/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Medium/Roboto-Medium.woff") format("woff"), url("../fonts/Roboto-Medium/Roboto-Medium.ttf") format("truetype"), url("../fonts/Roboto-Medium/Roboto-Medium.svg#Roboto-Medium") format("svg"); font-weight: 500; font-style: normal; font-stretch: normal; font-display: swap; }

@font-face { font-family: 'Roboto'; src: url("../fonts/Roboto-Bold/Roboto-Bold.eot"); src: url("../fonts/Roboto-Bold/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Bold/Roboto-Bold.woff") format("woff"), url("../fonts/Roboto-Bold/Roboto-Bold.ttf") format("truetype"), url("../fonts/Roboto-Bold/Roboto-Bold.svg#Roboto-Bold/Roboto-Bold") format("svg"); font-weight: 700; font-style: normal; font-stretch: normal; font-display: swap; }

@font-face { font-family: 'Roboto Condensed'; src: url("../fonts/RobotoCondensedRegular/RobotoCondensedRegular.eot"); src: url("../fonts/RobotoCondensedRegular/RobotoCondensedRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoCondensedRegular/RobotoCondensedRegular.woff") format("woff"), url("../fonts/RobotoCondensedRegular/RobotoCondensedRegular.ttf") format("truetype"), url("../fonts/RobotoCondensedRegular/RobotoCondensedRegular.svg#RobotoCondensedRegular") format("svg"); font-weight: 400; font-style: normal; font-stretch: normal; font-display: swap; }

@font-face { font-family: 'Roboto Condensed'; src: url("../fonts/RobotoCondensedBold/RobotoCondensedBold.eot"); src: url("../fonts/RobotoCondensedBold/RobotoCondensedBold.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoCondensedBold/RobotoCondensedBold.woff") format("woff"), url("../fonts/RobotoCondensedBold/RobotoCondensedBold.ttf") format("truetype"), url("../fonts/RobotoCondensedBold/RobotoCondensedBold.svg#RobotoCondensedBold") format("svg"); font-weight: 700; font-style: normal; font-stretch: normal; font-display: swap; }

@font-face { font-family: 'icomoon'; src: url("../fonts/icomoon/fonts/icomoon.eot"); src: url("../fonts/icomoon/fonts/icomoon.eot?#iefix") format("embedded-opentype"), url("../fonts/icomoon/fonts/icomoon.woff") format("woff"), url("../fonts/icomoon/fonts/icomoon.svg#icomoon") format("svg"); font-weight: bold; font-style: normal; font-display: swap; }

[class^="icon-"], [class*=" icon-"] { /* use !important to prevent issues with browser extensions that change fonts */ font-family: 'icomoon' !important; speak: none; font-style: normal; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; /* Better Font Rendering =========== */ -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.icon-close:before { content: "\e900"; }

.icon-up:before { content: "\e901"; }

/* Slider */
.slick-slider { position: relative; display: block; box-sizing: border-box; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -ms-touch-action: pan-y; touch-action: pan-y; -webkit-tap-highlight-color: transparent; }

.slick-list { position: relative; overflow: hidden; display: block; margin: 0; }

.slick-list:focus { outline: none; }

.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-track, .slick-slider .slick-list { transform: translate3d(0, 0, 0); }

.slick-track { position: relative; left: 0; top: 0; display: flex; margin-left: auto; margin-right: auto; }

.slick-loading .slick-track { visibility: hidden; }

.slick-slide { min-height: 1px; display: none; }

[dir="rtl"] .slick-slide { float: right; }

.slick-slide img { vertical-align: top; }

.slick-slide.slick-loading img { display: none; }

.slick-slide.dragging img { pointer-events: none; }

.slick-initialized .slick-slide { display: block; }

.slick-loading .slick-slide { visibility: hidden; }

.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }

.slick-arrow { background: transparent; border: 1px solid rgba(0, 0, 0, 0.2); border-radius: 50%; z-index: 10; padding: 0; position: absolute; top: 50%; color: #000; z-index: 2; width: 5rem; height: 5rem; color: #001118; background: transparent; display: flex; align-items: center; justify-content: center; font-size: 1.6rem; left: -5px; top: 50%; margin-top: -2.5rem; transition: color .6s ease-out, border-color .6s ease-out; }

.slick-arrow.slick-next { left: auto; right: -5px; }

.slick-prev { text-align: right; }

.slick-next { text-align: left; }

.web .slick-arrow:hover { border-color: rgba(125, 116, 228, 0.3); color: #7D74E4; }

.slick-arrow.slick-hidden { display: none; }

.slick-dots { list-style: none; margin-top: 12px; text-align: center; }

.slick-dots li { display: inline-block; margin: 0 6px; cursor: pointer; }

.slick-dots button { text-indent: -99999px; font-size: 0; border: 0; vertical-align: top; cursor: pointer; padding: 0; width: 12px; height: 12px; border-radius: 50%; background: #F0F1F1; }

.slick-dots .slick-active button { background: #B4DA4D; }

.slick-disabled, .slick-disabled:hover { opacity: 0.3; cursor: default; }

.main-wrapper { padding: 0; min-width: 320px; width: 100%; position: relative; min-height: 100%; min-height: 100%; display: flex; flex-direction: column; justify-content: flex-start; overflow: hidden; flex: 1 0 auto; }

.wrapper { min-width: 320px; max-width: 1208px; padding-right: 4rem; padding-left: 4rem; margin: 0 auto; position: relative; width: 100%; }

.wrapper-2 { max-width: 1520px; }

/* titles */
p { padding: 1.2rem 0; }

h1, .h1 { font-weight: bold; font-family: "Roboto Condensed", arial, sans-serif; margin: 0 0 24px; color: #001118; }

h1 { font-size: 40px; line-height: 50px; margin-bottom: 18px; }

.h1 { font-size: 24px; line-height: 34px; }

h2 { font-weight: bold; font-size: 32px; line-height: 42px; font-family: "Roboto Condensed", arial, sans-serif; margin: 0 0 24px; text-align: left; color: #001118; }

.h2 { font-weight: bold; font-size: 40px; line-height: 50px; font-family: "Roboto Condensed", arial, sans-serif; margin: 0 0 24px; text-align: left; color: #001118; display: flex; align-items: center; }

a.h2:hover { color: #001118; }

a.h2:hover .arrow-title { color: #30B1E9; transform: translateX(8px); }

.h2-1 .arrow-title { color: #30B1E9; }

a.h2-1:hover .arrow-title { color: #B4DA4D; }

.arrow-title { margin-left: 12px; width: 40px; height: 40px; flex-shrink: 0; background: rgba(0, 17, 24, 0.06); border-radius: 50%; display: flex; align-items: center; justify-content: center; color: #B4DA4D; transition: color .3s ease, transform .4s ease; }

.arrow-title svg { transition: color .4s ease; width: 24px; }

.icon { display: inline-block; width: 24px; height: 24px; stroke-width: 0; stroke: currentColor; fill: currentColor; }

.h2-lg { font-size: 54px; line-height: 60px; letter-spacing: 0.01em; }

h3, .h3 { font-weight: bold; font-size: 2.4rem; line-height: 28px; color: #001118; padding-bottom: 2rem; }

h4, .h4 { font-weight: bold; font-size: 2.4rem; line-height: 28px; color: #001118; }

/* text position */
.text-left { text-align: left !important; }

.text-center { text-align: center !important; }

.text-right { text-align: right !important; }

.nowrap { white-space: nowrap !important; }

.center { text-align: center; }

.pull-right { float: right; }

.pull-left { float: left; }

.hide { display: none !important; }

/*
================================================================================
|                                     CONTENT                                 |
================================================================================
*/
.content { min-width: 320px; text-align: left; width: 100%; order: 2; flex-grow: 1; position: relative; padding: 10px 0 108px; z-index: 1; }

.main-wrap { display: flex; flex-direction: column-reverse; }

.content-wrap { background: #FFFFFF; padding: 33px 2.5rem 2.1rem; max-width: 119.6rem; margin: 0 auto; }

.more-text { font-weight: 600; font-size: 15px; line-height: 20px; cursor: pointer; color: #30B1E9; text-align: right; margin-top: 23px; }

.more-text span { transition: color .3s ease-in; }

.web .more-text:hover span { color: #001118; }

.btn { color: #001118; font-family: Roboto, arial, sans-serif; border: 0; font-weight: 500; font-size: 1.4rem; line-height: 5.6rem; height: 5.6rem; text-align: center; padding: 0 1.5rem; cursor: pointer; z-index: 0; background: #B4DA4D; border-radius: .6rem; position: relative; text-align: center; display: inline-block; cursor: pointer; transition: background .3s ease-in, color .3s ease-in; }

.btn:hover { color: #001118; }

.web .btn:hover { background: #7BD2F8; }

.btn:focus, .btn:active { background: #30B1E9; }

.btn.active, .btn.active:hover, .btn:disabled, .btn:disabled:hover { color: rgba(0, 17, 24, 0.4); background: rgba(142, 216, 248, 0.4); }

.btn-2 { border: 2px solid #B4DA4D; background: #fff; transition: background .3s ease-in, color .3s ease-in; }

.btn-3 { background: linear-gradient(103.29deg, #B4DA4D 13.43%, #8ED8F8 52.29%, #B4DA4D 105.29%); background-size: 200% 100%; align-items: center; display: inline-flex; transition: background-position .3s ease-in, color .3s ease-in; justify-content: center; }

.btn-3 img { margin-left: 8px; height: 24px; }

.web .btn-2:hover { background: #B4DA4D; }

.web .btn-3:hover { background: linear-gradient(103.29deg, #B4DA4D 13.43%, #8ED8F8 52.29%, #B4DA4D 105.29%); background-size: 200% 100%; background-position: 200% 0; }

input[type="text"], input[type="search"], input[type="tel"], input[type="email"], textarea { border-radius: 6px; color: #001118; padding-left: 1.2rem; padding-right: 1.2rem; font-size: 1.6rem; font-family: Roboto, arial, sans-serif; width: 100%; height: 56px; border: 2px solid #f0f1f1; font-weight: 500; font-size: 1.4rem; background: #f0f1f1; transition: border .3s ease-in, background .3s ease-in; }

input[type="text"]:hover, input[type="search"]:hover, input[type="tel"]:hover, input[type="email"]:hover, textarea:hover { background: #e6e8e8; border-color: #e6e8e8; }

input[type="text"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="email"]:focus, textarea:focus { border-color: #30B1E9; background: #fff; }

input[type="text"].error, input[type="search"].error, input[type="tel"].error, input[type="email"].error, textarea.error { border-color: red; }

::-webkit-input-placeholder { color: rgba(0, 17, 24, 0.4); }

::-moz-placeholder { color: rgba(0, 17, 24, 0.4); }

:-moz-placeholder { color: rgba(0, 17, 24, 0.4); }

:-ms-input-placeholder { color: rgba(0, 17, 24, 0.4); }

textarea.form-control { height: 10rem; resize: none; line-height: 2.1rem; padding-top: 1rem; padding-bottom: 1.2rem; vertical-align: top; resize: vertical; }

.form-control-2 { color: #001118; padding-left: 1.2rem; padding-right: 1.2rem; font-size: 1.6rem; font-family: Roboto, arial, sans-serif; width: 100%; height: 5rem; font-weight: 500; font-size: 1.4rem; border: 1px solid rgba(19, 19, 19, 0.2); border-radius: 10px; transition: border .3s ease-in; }

.form-control-2:focus { border-color: #7D74E4; }

.form-control-2.error { border-color: red; }

.box-field { margin-bottom: 1.4rem; }

.btn_submit { width: 100%; }

.box-field__label { font-weight: 500; font-size: 14px; line-height: 20px; margin-bottom: 7px; }

.box-field__label sup { vertical-align: top; color: #FF6948; }

.label-placeholder { color: rgba(37, 35, 35, 0.6); text-align: left; padding-left: 0; top: .6rem; display: block; position: absolute; left: 0; font-size: 1.4rem; font-weight: 500; }

label.error { color: red; font-size: 13px; font-weight: 500; margin: 8px 0 0 1.5rem; }

.box-field__input { position: relative; }

.label-placeholder { letter-spacing: 0.02em; position: absolute; left: 0; text-align: left; padding-left: 0; top: .7rem; font-weight: 400; display: block; position: absolute; font-size: 1.6rem; line-height: 2.1rem; color: rgba(255, 255, 255, 0.8); pointer-events: none; }

.filled .label-placeholder { font-size: 80%; top: -18px; opacity: 0; }

.checkbox-element { cursor: pointer; position: relative; display: inline-flex; }

.checkbox-element input { flex-shrink: 0; display: inline-block; margin-top: 0; position: absolute; left: 0; top: 0; }

.checkbox-element input:checked ~ .check { border-color: transparent; }

.checkbox-element input:checked ~ .check:after { opacity: 1; }

.checkbox-element { padding-left: 35px; display: inline-flex; min-height: 20px; position: relative; z-index: 0; }

.checkbox-element:hover ~ .checkbox-text .check { border-color: #30B1E9; }

.checkbox-element input[type="checkbox"]:checked ~ .checkbox-text .check, .checkbox-element input[type="radio"]:checked ~ .checkbox-text .check { background-color: #8ED8F8; background-image: url(../img/icons-svg/check.svg); background-repeat: no-repeat; background-size: 13px 11px; background-position: 50% 50%; transition: background-color 0.3s ease-in, border 0.3s ease-in; }

.check { background: transparent no-repeat center; background-size: 1.1rem 1.1rem; border: 2px solid #001118; width: 22px; height: 22px; display: inline-block; padding: 0; flex-shrink: 0; background: none; position: relative; border-radius: 0; margin: 1px 0 0; position: absolute; left: 0; top: 0; z-index: 0; }

.checkbox-element input { opacity: 0; position: absolute; }

.checkbox-text { font-weight: 500; font-size: 12px; line-height: 18px; color: #001118; cursor: pointer; }

.hide { display: none; }

.color-decor { color: rgba(0, 144, 240, 0.5); }

.tabs-nav-wrap { overflow: auto; }

.tabs-nav__link { position: relative; display: inline-block; font-weight: 600; font-size: 1.4rem; line-height: 120%; padding-bottom: 13px; height: 3.4rem; line-height: 3.4rem; cursor: pointer; border-radius: 2rem; padding: 0 1.6rem; white-space: nowrap; transition: color .4s ease, background .4s ease; }

.tabs-nav__link span { color: rgba(0, 17, 24, 0.6); }

.tabs-nav__link:last-child { margin-right: 0; }

.tabs-nav__link.active, .tabs-nav__link:hover { background: #EBE0D3; color: #30B1E9; }

.tabs-nav__link.active { cursor: default; }

.web .tabs-nav__link:hover:before { transform: scale(1); }

.tabs-wrap { margin-top: 3.8rem; position: relative; }

.tab { position: absolute; z-index: -1; left: 0; top: 0; right: 0; visibility: hidden; }

.tab.active { position: static; visibility: visible; }

.arrow-scroll-top { position: fixed; bottom: 24px; right: 5.6%; z-index: 210; transform: scale(0); will-change: transform; transition: all .3s linear; }

.arrow-scroll-top.shown { transform: scale(1); }

@keyframes fadeToggle { from { opacity: 0; }
  49.99% { opacity: 0; }
  50% { opacity: 1; }
  to { opacity: 1; } }

@keyframes fadeToggle2 { from { opacity: 1; }
  49.99% { opacity: 1; }
  50% { opacity: 0; }
  to { opacity: 0; } }

.button-arrow { display: flex; justify-content: center; align-items: center; padding: 0; background: rgba(0, 17, 24, 0.06); width: 56px; color: #001118; font-size: 23px; height: 56px; box-shadow: 0 2px 6px 0 rgba(0, 1, 1, 0); border-radius: 50%; cursor: pointer; transition: box-shadow .3s ease; }

.web .button-arrow:hover { box-shadow: 0 1px 5px 0 rgba(0, 1, 1, 0.5); }

.scrollDisabled { overflow: hidden; position: fixed !important; margin-top: 0; width: 100%; z-index: 0; }

.scrollDisabled.web, .scrollDisabled.web.compensate-for-scrollbar, .scrollDisabled.web .header { padding-right: 1.7rem; }

.scrollDisabled .main-wrapper { overflow: visible; }

@media screen and (min-width: 768px) { .mob-show { display: none !important; } }

@media screen and (min-width: 1024px) { .tablet-show { display: none !important; } }

@media screen and (max-width: 1439px) { .arrow-scroll-top { right: 3px; } }

@media screen and (max-width: 1100px) { .wrapper { padding-right: 3rem; padding-left: 3rem; } }

@media screen and (max-width: 1023px) { .wrapper { padding-left: 2.4rem; padding-right: 2.4rem; }
  .content { padding-bottom: 90px; }
  .tablet-hide { display: none !important; }
  .h2-lg { font-size: 45px; line-height: 50px; } }

@media screen and (max-width: 767px) { .content { padding-bottom: 100px; }
  .mob-hide { display: none !important; }
  .tab { padding-left: 0; }
  .h2-lg { font-size: 40px; line-height: 50px; }
  .more-text { margin-top: 11px; }
  .arrow-scroll-top { display: none; } }

@media screen and (max-width: 359px) { .wrapper { padding-left: 12px; padding-right: 12px; }
  .sm-mob-hide { display: none !important; } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { .text-gradient, .arrow-down i.text-gradient { background: none; color: #41FF6B; } }

.header { z-index: 10; background: #fff; flex-shrink: 0; transition: box-shadow .3s ease-in; /*    &.fixed { box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1); .header-wrapper_bottom { height: 0 } .menu-catalog { top: 15px; } .menu-box { display: none; } }*/ }

.header .wrapper { display: flex; justify-content: space-between; align-items: center; }

.header .wrapper:after { display: none; }

.header-wrapper_top { padding: 11px 0 10px; }

.header-wrapper_bottom { margin-top: 38px; }

.header-wrapper_bottom .wrapper { flex-wrap: wrap; }

.header-phone { font-size: 12px; line-height: 18px; margin-right: 24px; }

.header-phone a { display: inline-flex; align-items: center; }

.fixed .header-navs-wrap { background: #fff; position: fixed; box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1); left: 0; top: 0; right: 0; z-index: 100; }

.fixed .nav-logo .logo { top: 0; }

.fixed .nav-logo .logo img { max-height: 68px; width: auto; }

.shops-link { margin-right: 24px; font-size: 12px; line-height: 18px; color: rgba(0, 17, 24, 0.6); }

.shops-link:hover { color: #30B1E9; }

.nav-inner { flex-shrink: 10; display: flex; align-items: center; justify-content: space-between; }

.header-info { padding-left: 15px; width: calc(100% - 230px); display: flex; align-items: center; justify-content: flex-end; flex-shrink: 0; }

.header-cart, .header-favorite { background: rgba(0, 17, 24, 0.06); border-radius: 50%; width: 5.6rem; height: 5.6rem; position: relative; cursor: pointer; display: inline-flex; align-items: center; justify-content: center; transition: background .4s ease; }

.header-cart svg, .header-favorite svg { width: 2.4rem; }

.header-cart:hover, .header-favorite:hover { background: rgba(48, 177, 233, 0.16); }

.header-cart { position: relative; margin: 0 39px 0 0; }

.header-cart__badge { color: #fff; position: absolute; width: 20px; height: 20px; border-radius: 50%; right: 1px; top: -1px; display: flex; align-items: center; justify-content: center; background: #FF6948; font-weight: bold; font-size: 14px; line-height: 20px; padding-top: 1px; transition: transform .3s ease; }

.header-cart__btns { margin-top: 2.5rem; display: flex; align-items: center; justify-content: space-between; }

.header-cart__row { display: flex; align-items: center; justify-content: flex-end; }

.header-favorite { margin-right: 24px; }

.header-favorite svg { color: #FF6948; }

.header-login { font-weight: 500; font-size: 14px; line-height: 20px; color: #001118; display: inline-flex; align-items: center; }

.header-login svg { width: 24px; height: 24px; }

.header-login span { display: inline-block; margin: 2px 8px 0 0; }

.menu-box { margin-top: 22px; width: 100%; }

.nav-logo .logo { top: -13px; position: relative; z-index: 0; }

.logo { display: block; width: 148px; }

.bars-mob { width: 50px; height: 50px; padding: 5px; margin: 0 25px 0 -15px; display: none; position: relative; cursor: pointer; }

.bars-mob .hamburger { position: absolute; height: 2.3rem; width: 2rem; margin-top: 1.5rem; margin-left: 1rem; }

.bars-mob .hamburger span { display: block; width: 100%; height: .2rem; position: relative; background: #131313; top: 0; left: 0; margin: 0 0 .4rem; transition: .25s ease-in-out; }

.bars-mob .hamburger span:nth-child(1) { transition-delay: .5s; }

.bars-mob .hamburger span:nth-child(2) { transition-delay: .625s; }

.bars-mob .hamburger span:nth-child(3) { transition-delay: .75s; }

.bars-mob .cross { position: absolute; height: 19px; width: 19px; margin: 9px 0 0 10px; transform-origin: center center; transform: rotate(45deg); }

.bars-mob .cross span { display: block; background: #131313; transition: .25s ease-in-out; }

.bars-mob .cross span:nth-child(1) { height: 0%; width: .2rem; position: absolute; left: 11px; top: 2%; transition-delay: 0s; }

.bars-mob .cross span:nth-child(2) { width: 0%; height: .2rem; position: absolute; left: 15%; top: 8px; transition-delay: .25s; }

.bars-mob.active .hamburger span { width: 0%; }

.bars-mob.active .hamburger span:nth-child(1) { transition-delay: 0s; }

.bars-mob.active .hamburger span:nth-child(2) { transition-delay: .125s; }

.bars-mob.active .hamburger span:nth-child(3) { transition-delay: .25s; }

.bars-mob.active .cross span:nth-child(1) { height: 100%; transition-delay: .625s; }

.bars-mob.active .cross span:nth-child(2) { width: 100%; transition-delay: .375s; }

.bars-mob, .bars-mob * { cursor: pointer; }

.header-contacts { display: flex; align-items: center; }

.header-contacts_mob { display: none; }

.loaded .menu-box { transition: none; }

.messangers { display: inline-flex; align-items: center; }

.messangers img { max-width: 20px; max-height: 20px; opacity: .6; transition: opacity .4s ease; }

.messangers__link { margin: 0 12px; transition: none; }

.messangers__link:hover img { opacity: 1; }

.header .messangers { position: relative; top: 2px; margin-right: 13px; }

.open-header { background: #ccc; }

.open-header .header { z-index: 1000; }

.open-header .content, .open-header footer { opacity: 0.7; }

.menu-catalog, .menu-mob { list-style: none; display: flex; }

.menu-catalog li, .menu-mob li { margin-right: 1.5rem; }

.menu-catalog li:hover ul, .menu-mob li:hover ul { opacity: 1; transform: translateY(0); visibility: visible; }

.menu-catalog li:hover > a, .menu-mob li:hover > a { color: #30B1E9; }

.menu-catalog a, .menu-mob a { font-weight: 500; font-size: 15px; line-height: 20px; color: #001118; }

.menu-catalog { padding: 0 0 3px 5px; position: relative; }

.menu-catalog li { margin: 0 28px 0 0; }

.menu-catalog ul { background: #F0F1F1; border-radius: .6rem; position: absolute; list-style: none; padding: 23px 1px; left: 0; margin-top: -2px; top: 100%; width: 1128px; z-index: 100; opacity: 0; transform: translateY(1.5rem); visibility: hidden; transition: all .5s ease; display: flex; }

.menu-catalog ul > li { flex-shrink: 0; }

.menu-catalog ul > li:last-child { width: 100%; flex-shrink: 10; }

.menu-catalog ul li { margin: 0; }

.menu-catalog ul li li { margin: 0 45px 10px 0; }

.menu-catalog ul.cat-1 li { margin-right: 0; }

.menu-catalog ul ul { padding: 0 22px; position: static; display: inline-grid; width: auto; grid-template-rows: repeat(4, auto); grid-auto-flow: column; }

.menu-catalog ul a { padding: 0; font-size: 14px; white-space: nowrap; }

.menu-catalog ul a:hover { background: #F6F2EC; color: #30B1E9; }

.menu-catalog > li { padding: 11px 0; }

.menu-catalog > li > a { padding: 3px 0; position: relative; }

.menu-catalog > li > a:before { content: ''; display: block; right: -4px; bottom: -3px; position: absolute; height: 4px; left: -4px; opacity: 0; transition: opacity .4s ease; background: #B3DD41; }

.menu-catalog > li > a:hover { color: #001118; }

.menu-catalog > li > a:hover:before { opacity: 1; }

.cat-title { font-size: 12px; color: rgba(0, 17, 24, 0.4); }

.menu-mob { display: none; }

.arr-menu { font-size: .7rem; color: #30B1E9; margin: -.2rem 0 0 .5rem; padding: .3rem; display: inline-block; cursor: pointer; transition: transform .4s ease; }

.open-filters .content { position: relative; z-index: 11110; }

.header-navs { width: 100%; display: flex; align-items: center; justify-content: space-between; }

.header-navs__left { display: flex; align-items: center; padding-right: 30px; width: calc(100% - 250px); max-width: 800px; }

.header-navs__right { margin-left: auto; display: flex; align-items: center; }

.main-menu { padding: 9px 15px 11px; background: rgba(0, 17, 24, 0.06); list-style: none; display: flex; width: 100%; }

.main-menu li { list-style: none; margin: 0 30px 0 0; }

.main-menu li:last-child { margin-right: 0; }

.main-menu .current-menu-item a { cursor: default; }

.main-menu a { font-size: 12px; line-height: 18px; color: #001118; transition: color .3s ease; }

.main-menu a:hover { color: #30B1E9; }

.web .main-menu a:hover, .main-menu .current-menu-item a { color: #30B1E9; }

.header-town { font-size: 12px; line-height: 18px; display: flex; align-items: center; width: 230px; }

.header-town__val { color: #30B1E9; cursor: pointer; margin-left: 3px; transition: color .4s ease; }

.header-town__val:hover { color: #B4DA4D; }

.header-town__label { flex-shrink: 0; }

.search-block { max-width: 552px; width: calc(100% - 194px); margin-left: 44px; position: relative; }

.search-block__form { position: relative; }

.search-block input[type="text"], .search-block input[type="search"] { padding-right: 62px; }

.search-block input[type="text"].filled ~ .close-popup-search, .search-block input[type="search"].filled ~ .close-popup-search { display: flex; opacity: 1; }

.search-block input[type="text"].filled ~ [type="submit"], .search-block input[type="search"].filled ~ [type="submit"] { opacity: 0; display: block; }

.search-block input[type="text"].filled ~ .close-popup-search, .search-block input[type="search"].filled ~ .close-popup-search { border-left-color: rgba(0, 17, 24, 0.06); }

.search-block [type="submit"] { border: 0; height: 52px; line-height: 20px; padding: 7px 2px; width: 45px; background: none; line-height: 52px; text-align: center; display: inline-block; position: absolute; right: 2px; top: 2px; }

.search-block [type="submit"] svg { width: 24px; height: 24px; color: #B4DA4D; transition: color .4s ease; }

.search-block [type="submit"]:hover svg { color: #30B1E9; }

.search-block [type="submit"]:before { content: ''; display: block; width: 2px; background: rgba(0, 17, 24, 0.06); top: 8px; bottom: 8px; left: -3px; position: absolute; }

.search-block.active .close-popup-search { display: flex; opacity: 0; }

.search-block.active .close-popup-search { opacity: 1; }

.search-block.active [type="submit"] { opacity: 0; }

.search-dropdown { padding: 2px 0 0; background: #FFFFFF; position: absolute; top: 100%; left: 0; right: 0; z-index: 100; display: none; box-shadow: 4px 4px 14px 2px rgba(0, 46, 89, 0.15); border-radius: 6px; color: #001118; font-size: 14px; line-height: 20px; }

.search-dropdown ul { list-style: none; }

.search-dropdown ul li:first-child a { border-radius: 6px 6px 0 0; }

.search-dropdown ul li:last-child a { border-radius: 0 0 6px 6px; }

.search-dropdown a { padding: 12px; color: inherit; display: block; transition: background .4s ease, font-weight .4s ease; }

.search-dropdown a:hover { color: inherit; font-weight: 500; background: rgba(240, 241, 241, 0.8); }

.close-popup-search, .clear-popup-search, .ms-choice > div.icon-close { position: absolute; right: 2px; width: 48px; top: 10px; bottom: 10px; color: #001118; border-left: 2px solid #8ED8F8; cursor: pointer; display: none; justify-content: center; align-items: center; opacity: 0; }

.close-popup-search:hover, .clear-popup-search:hover, .ms-choice > div.icon-close:hover { color: #30B1E9; }

.header-navs__menu { margin-top: 13px; display: flex; align-items: center; }

.header-categories { display: flex; align-items: center; list-style: none; }

.header-categories li { margin-right: 24px; }

.header-categories a { font-weight: 500; font-size: 15px; line-height: 20px; border-radius: 6px; display: inline-block; padding: 4px 12px; background: rgba(180, 218, 77, 0.6); transition: none; color: #001118; }

.header-categories a:hover { color: #001118; }

.header-categories a:focus, .header-categories a:active, .header-categories aactive { background: #7BD2F8; }

.web .header-categories a { transition: background .4s ease; }

.web .header-categories a:hover { background: #7BD2F8; }

.header-language { font-size: 12px; line-height: 18px; color: rgba(0, 17, 24, 0.6); padding-right: 6px; display: flex; align-items: center; }

.header-language img { height: 16px; vertical-align: middle; }

.header-language__title { padding-left: 9px; }

.header-language:hover { color: #30B1E9; }

.header-search-icon { margin-right: 24px; background: rgba(0, 17, 24, 0.06); border-radius: 50%; width: 5.6rem; height: 5.6rem; position: relative; cursor: pointer; display: inline-flex; align-items: center; justify-content: center; transition: background .4s ease; }

.header-search-icon svg { color: #B4DA4D; }

.header-search-icon:hover { background: rgba(48, 177, 233, 0.16); }

.button-login { border-bottom: 2px solid rgba(0, 17, 24, 0.06); padding-bottom: 12px; margin-bottom: 9px; }

.btn_login { width: 100%; height: 40px; line-height: 39px; flex-shrink: 0; }

.header-links { color: #001118; font-weight: 500; font-size: 14px; line-height: 20px; order: 2; }

.header-links a { color: inherit; }

.header-links__item { margin-bottom: 4px; }

.header-links__icon { padding: 2px 0 0 2px; position: relative; display: inline-block; width: 26px; margin-right: 3px; vertical-align: middle; }

.header-links__link { color: #001118; }

.header-links .icon { color: #B4DA4D; }

.header-links .icon-favorite { color: #FF6948; }

.header-links__text { margin-left: 5px; }

.header-links__text span { color: rgba(0, 17, 24, 0.6); }

.filters-opened .header { z-index: 0; }

@media screen and (max-width: 1207px) { .menu-catalog ul { width: calc(100vw - 80px); } }

@media screen and (max-width: 1200px) { .header-categories a { font-size: 14px; padding: 5px 10px; }
  .menu-catalog { padding-left: 0; }
  .header-categories li { margin-right: 17px; }
  .header-language { padding-right: 0; } }

@media screen and (max-width: 1100px) { .header-wrapper_bottom { margin-top: 29px; }
  .menu-catalog a { font-size: 14px; }
  .menu-catalog > li { margin-right: 20px; }
  .header-categories a { padding: 5px 9px; } }

@media screen and (max-width: 1023px) { .bars-mob, .menu-mob { display: block; }
  .bars-mob { flex-shrink: 0; }
  .menu-box { position: fixed; left: -100%; background: #F0F1F1; bottom: 0; top: 98px; width: 360px; max-width: 100%; margin: 0; display: block; padding: 24px 24px 15px; z-index: 100; overflow: auto; transition: left .5s ease; display: flex; flex-direction: column; justify-content: flex-start; whill-change: left; }
  .menu-box.active { left: 0; }
  .search-block { display: none; position: fixed; left: 0; top: 0; right: 0; margin: 0; background: #fff; max-width: 100vw; width: 100vw; bottom: 0; z-index: 101; }
  .search-block.shown { display: block; }
  .close-popup-search { display: flex; opacity: 1; border-left-color: transparent; }
  .search-block input[type="text"], .search-block input[type="search"] { padding-left: 60px; }
  .search-block input[type="text"]:focus, .search-block input[type="search"]:focus { border-color: #f0f1f1; background: #f0f1f1; }
  .search-block [type="submit"] { left: 0; width: 56px; padding-left: 17px; }
  .search-block [type="submit"] svg { color: #8ED8F8; }
  .search-block.active [type="submit"] { opacity: 1; }
  .search-dropdown { position: static; box-shadow: none; border-radius: 0; }
  .btn_login { order: 1; }
  .header-navs__menu { display: block; margin-top: 3px; padding-top: 12px; border-top: 2px solid rgba(0, 17, 24, 0.06); order: 2; }
  .nav-inner { padding-top: 10px; flex-shrink: 0; order: 3; border-top: 2px solid rgba(0, 17, 24, 0.06); }
  .main-menu li { margin: 0 0 9px; }
  .menu-catalog { display: block; padding: 0; }
  .main-menu { background: none; padding: 0; display: block; }
  .header-categories { border-top: 2px solid rgba(0, 17, 24, 0.06); margin: 0 -12px 0 0; padding-top: 11px; flex-wrap: wrap; }
  .header-categories li { margin: 0 12px 12px 0; }
  .header-categories a { padding: 12px 14px; }
  .menu-catalog ul { margin-top: 7px; padding: 0; position: static; width: 100%; display: block; opacity: 1; visibility: visible; transform: none; }
  .menu-catalog > li { margin-right: 0; padding: 0; margin-bottom: 15px; display: flex; align-items: center; justify-content: space-between; flex-wrap: wrap; }
  .menu-catalog > li > span { font-weight: 500; font-size: 14px; line-height: 20px; color: #001118; width: calc(100% - 30px); }
  .menu-catalog ul ul li { margin: 0 0 15px; }
  .menu-catalog ul ul { padding: 0 13px; display: none; }
  .menu-catalog ul > li:last-child { margin-bottom: 1px; }
  .menu-catalog .active ul { display: block; }
  .menu-catalog .active i.js-arr-menu { transform: rotateX(180deg); }
  .menu-catalog i.js-arr-menu { display: inline-flex; height: 24px; width: 24px; margin: -1px -1px 0 0; transition: transform .5s ease; }
  body:not(.loaded) .menu-catalog i.js-arr-menu { background: url(../img/icons-svg/down.svg) 50% 50% no-repeat; background-size: contain; }
  .overlay { position: fixed; z-index: 99; background: rgba(0, 0, 0, 0.6); left: 0; right: 0; bottom: 0; top: 98px; visibility: hidden; opacity: 0; transition: opacity .4s ease; }
  .open-header .overlay { visibility: visible; opacity: 1; }
  .logo { display: block; width: 138px; }
  .header-town__val { max-width: 200px; display: inline-block; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; vertical-align: middle; }
  .logo { width: 132px; } }

@media screen and (max-width: 767px) { .header-contacts .messangers, .header-phone { display: none; }
  .header-cart, .header-favorite, .header-search-icon { margin: 0 2px 0 0; width: 38px; height: 38px; background: none; }
  .logo img { height: 40px; }
  .header-cart__badge { width: 18px; height: 18px; font-size: 14px; line-height: 18px; right: -5px; top: -2px; }
  .header-wrapper_top { padding: 3px 0 6px; }
  .header-login { margin-left: 20px; }
  .bars-mob { margin-right: 1px; }
  .bars-mob .hamburger { margin: 13px 0 0 12px; }
  .bars-mob .cross { margin: 9px 0 0 10px; }
  .header-wrapper_bottom { margin-top: 21px; }
  .nav-logo .logo { top: -5px; }
  .header-cart { margin-right: -7px; }
  .header-navs__left { width: calc(100% - 180px); } }

@media screen and (max-width: 479px) { .shops-link, .header-login span, .header-login { display: none; }
  .logo { width: 102px; } }

@media screen and (max-width: 359px) { .bars-mob { margin-left: -12px; }
  .header-cart { margin-right: -1px; } }

@media screen and (min-width: 480px) { .button-login { display: none; } }

@media screen and (min-width: 1024px) { .header-search-icon { display: none; } }

.footer { order: 3; }

.section-main { position: relative; }

.section-main .wrapper { z-index: 1; }

.main-bl__head { width: 43%; padding: 4rem 2rem 4rem 8.6%; display: flex; justify-content: center; flex-direction: column; }

.main-title { font-family: "Roboto Condensed", arial, sans-serif; font-size: 54px; line-height: 60px; font-weight: 700; }

.section-subtitle { margin: 12px 0 0; font-size: 24px; line-height: 34px; }

.main-bl__arrow { margin-top: 2.7rem; }

.main-bl__arrow .btn-2 { min-width: 17.2rem; }

.main-bl__picture { position: relative; width: 57%; padding: 0; display: flex; flex-direction: column; }

.main-bl__picture img, .main-bl__picture picture { vertical-align: top; }

.main-bl__picture picture { display: block; flex: 1 1 100%; }

.main-bl__picture picture img { width: 100%; object-fit: cover; object-position: 0 50%; height: 100%; }

.slider-main:not(.slick-initialized) .slider-main__card { background: #8ED8F8; }

.slider-main__card { display: flex; min-height: 34.4rem; position: relative; z-index: 1; }

.slider-main { position: relative; z-index: 1; }

.slider-main .slick-track { display: flex; }

.slider-main .slick-track:before, .slider-main .slick-track:after { display: none; }

.slider-main__head { max-width: 49.4rem; width: 60%; flex-shrink: 0; }

.slider-main__item { overflow: hidden; position: relative; color: #001118; display: block; }

.slider-main__item:hover { color: #001118; }

.decor { width: 29%; z-index: 0; position: absolute; background: rgba(255, 255, 255, 0.4); border-radius: 50%; }

.decor:before { content: ''; display: block; padding-top: 100%; }

.decor-1 { bottom: 0; right: 28%; transform: translateY(28%); }

.decor-2 { bottom: 0; right: 0; transform: translate(10%, -25%); }

.web .main-bl__heading { opacity: 0; transform: translateX(-3rem); transition: opacity .5s ease 0.1s, transform .5s ease 0.1s; }

.web .main-bl__arrow { opacity: 0; transform: translateX(-4.5rem); }

.web .main-bl-exellence { opacity: 0; transform: translateX(-4.5rem); }

.web.content-loaded .main-bl__picture { transition: opacity .5s ease 0.3s, transform .5s ease 0.3s; }

.web.content-loaded .main-bl__arrow { transition: opacity .5s ease, transform .5s ease; }

.web.content-loaded .main-bl-exellence { transition: opacity .7s ease, transform .7s ease; }

.web:not(.content-loaded) .main-text, .web:not(.content-loaded) .main-bl__picture, .web:not(.content-loaded) .main-arrow, .web:not(.content-loaded) .main-bl-exellencem .web:not(.content-loaded) .main-bl__heading { -webkit-transition: none; -o-transition: none; transition: none; }

.web:not(.content-loaded) .main-bl__picture { opacity: 0; transform: translateX(3rem); }

.web:not(.content-loaded) .main-bl__heading { opacity: 0; transform: translateX(-3rem); }

.web:not(.content-loaded).main-arrow { opacity: 0; transform: translateX(4.5rem); }

.web:not(.content-loaded) .main-bl__arrow { opacity: 0; transform: translateX(-3rem); }

.slick-slide .main-bl__heading { opacity: 0; transform: translateX(-3rem); }

.slick-slide .main-bl__arrow { opacity: 0; transform: translateX(-4.5rem); }

.slick-slide .main-bl-exellence { opacity: 0; transform: translateX(-4.5rem); }

.main-bl__heading { transition: opacity .8s ease, transform .8s ease; }

.slick-current .main-bl__heading { transition: opacity .8s ease .2s, transform .8s ease .3s; opacity: 1; transform: translateX(0); }

.catalog { display: flex; flex-wrap: wrap; margin: 0 -12px; }

.gallery-wrap { overflow: auto; }

.section-main + .main-catalog { margin-top: 70px; }

.main-catalog { padding: 40px 0; overflow: hidden; }

.catalog_main { display: block; margin: 0 -24px 0 0; }

.catalog_main .products-card { margin: 0 24px 0 0; }

.products-card { background: #F0F1F1; width: 168px; padding: 12px; margin: 0 12px 40px; display: flex; flex-direction: column; justify-content: space-between; flex-shrink: 0; }

.products-card__head { min-height: 82px; }

.slick-initialized .products-card { display: flex; }

.products-card__title { font-size: 15px; line-height: 20px; font-family: "Roboto Condensed", arial, sans-serif; }

.products-card__title a { color: #001118; }

.products-card__title a:hover { color: #30B1E9; }

.products-card__img { margin-bottom: 12px; display: flex; align-items: center; justify-content: center; }

.products-card__img picture { height: 190px; overflow: hidden; box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2), 0px 6px 12px rgba(0, 46, 89, 0.12); display: inline-flex; align-items: center; justify-content: center; }

.products-card__img img { max-height: 100%; vertical-align: top; transition: transform .4s ease; }

.products-card__img:hover { transform: scale(1.05); }

.products-card__row { display: flex; align-items: center; }

.buy-add__button { height: 40px; padding: 0 5px; line-height: 40px; width: calc(100% - 48px); display: inline-flex; align-items: center; justify-content: center; text-align: center; }

.buy-add__button svg { margin-right: 8px; width: 25px; color: #30B1E9; }

.buy-add__text { font-weight: 500; font-size: 14px; line-height: 20px; }

.products-card__favorite { margin-left: 8px; height: 40px; width: 40px; background: #fff; display: flex; align-items: center; justify-content: center; border-radius: 6px; transition: none; }

.products-card__favorite svg { width: 24px; color: #8ED8F8; }

.products-card__favorite.in-favorite svg { color: #FF6948; }

.web .products-card__favorite svg { transition: color .4s ease; }

.web .products-card__favorite:hover svg { color: #B4DA4D; }

.products-card__author { margin-top: 5px; font-weight: 300; font-size: 12px; line-height: 18px; color: rgba(0, 17, 24, 0.6); }

.products-card__author a { color: inherit; transition: color .4s ease; }

.products-card__author a:hover { color: #30B1E9; }

.products-card__row { margin-top: 12px; }

@keyframes bouncing-loader { 0% { opacity: 1; }
  20% { opacity: 1; }
  21% { opacity: .4; }
  to { opacity: 0.4; } }

.bouncing-loader-wrap { padding: 90px 0 20px; text-align: center; }

.bouncing-loader { width: 39px; height: 39px; display: inline-flex; justify-content: center; flex-wrap: wrap; transform: rotate(45deg); }

.bouncing-loader > div { width: 50%; }

.bouncing-loader > div:nth-child(even) { text-align: right; }

.bouncing-loader > div span { display: inline-block; width: 16px; height: 16px; background: #8ED8F8; border-radius: 50%; animation: bouncing-loader .6s infinite alternate; }

.bouncing-loader > div.el-green span { background: #B4DA4D; }

.bouncing-loader > div:nth-child(2) span { animation-delay: 0.25s; }

.bouncing-loader > div:nth-child(3) span { animation-delay: 0.7s; }

.bouncing-loader > div:nth-child(4) span { animation-delay: 0.5s; }

.main-catalog + .main-catalog { margin-top: 80px; }

@media screen and (max-width: 1366px) { .section-main + .main-catalog { margin-top: 31px; }
  .main-catalog + .main-catalog { margin-top: 50px; } }

@media screen and (max-width: 1210px) { .catalog_main .slick-list { overflow: visible; }
  .main-bl__head { padding-left: 6%; }
  .main-title { font-size: 51px; line-height: 54px; }
  .section-subtitle { margin: 10px 0 0; font-size: 22px; line-height: 27px; } }

@media screen and (max-width: 1199px) { .catalog_main { margin: 0 24px 0 0; }
  .catalog_main .products-card { margin: 0 24px 0 0; } }

@media screen and (max-width: 1023px) { .main-title { font-size: 47px; line-height: 49px; }
  .section-subtitle { margin: 8px 0 0; font-size: 20px; line-height: 25px; }
  .slider-main__card { min-height: 300px; } }

@media screen and (max-width: 767px) { .slider-main { margin: 0 -24px; }
  .main-bl__head { position: relative; z-index: 1; }
  .slider-main__card { flex-direction: column; justify-content: space-between; width: 100%; }
  .slider-main__card picture img { object-position: 50% 0; height: 28.5rem; }
  .slick-initialized .slider-main__item { display: flex; flex: 1 1 100%; }
  .main-catalog { padding: 20px 0; }
  .main-catalog_bg { padding: 40px 0; }
  .main-catalog + .main-catalog { margin-top: 19px; }
  .main-catalog_bg + .main-catalog { margin-top: 41px; }
  .main-catalog + .main-catalog_bg { margin-top: 39px; }
  .catalog_main:not(.slick-initialized) { display: flex; }
  .main-bl__head { width: 100%; padding: 40px 24px 0; }
  .main-bl__picture { margin-top: -24px; width: 100%; }
  .main-title { font-size: 54px; line-height: 60px; }
  .section-subtitle { margin: 12px 0 0; font-size: 18px; line-height: 28px; }
  .section-main + .main-catalog { margin-top: 31px; }
  .decor { width: 48.88%; }
  .decor-1 { top: 38%; bottom: auto; right: 0; transform: translate(21%, -50%); }
  .decor-2 { width: 88.38%; bottom: 0; left: 0; transform: translate(-35%, 9%); } }

@media screen and (max-width: 359px) { .buy-add__button svg { margin-right: 3px; }
  .main-title { font-size: 34px; line-height: 40px; }
  .slider-main__card picture img { height: 25.5rem; } }

@media screen and (min-width: 1200px) { .bouncing-loader-wrap { padding: 164px 0 56px; } }
