@import "base/_reset";
@import "base/_fonts";
@import "layout/_icons";
@import "components/_slick";
//@import "components/_jquery-ui";
@import "layout/_content";
@import "layout/_header";
.footer {
    order: 3
}

.section-main {
    position: relative;
}

.section-main .wrapper {
    z-index: 1
}

.main-bl__head {
    width: 43%;
    padding: 4rem 2rem 4rem 8.6%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.main-title {
font-family: $custom-font-family;
font-size: 54px;
line-height: 60px;
    font-weight: 700;
}

.section-subtitle {
    margin: 12px 0 0;
font-size: 24px;
line-height: 34px;
}

.main-bl__arrow {
    margin-top: 2.7rem;
    .btn-2 {
        min-width: 17.2rem;
    }
}

.main-bl {
    &__picture {
        position: relative;
        width: 57%;
        padding: 0;
        display: flex;
        flex-direction: column;
        img,
        picture {
            vertical-align: top
        }
    }


    &__picture picture {
        display: block;
        flex: 1 1 100%;

        img {
            width: 100%;
            object-fit: cover;
            object-position: 0 50%;
        height: 100%;
        }
    }
}
.slider-main:not(.slick-initialized) .slider-main__card {
    background: $blue;
}
.slider-main__card {
    display: flex;
    min-height: 34.4rem;
    position: relative;
    z-index: 1
}

.slider-main {
    position: relative;
    z-index: 1;
    .slick-track {
        display: flex;
        &:before,
        &:after {
            display: none;
        }
    }
    &__head {
        max-width: 49.4rem;
        width: 60%;
        flex-shrink: 0
    }

    &__item {
            overflow: hidden;
    position: relative;
    color: $dark;
    display: block;
    &:hover {
        color: $dark;
    }
    } 
}

.decor {
    width: 29%;
    z-index: 0;
    position: absolute;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 50%;
    &:before {
        content: '';
        display: block;
        padding-top: 100%
    }
        &-1 {
            bottom: 0;
            right: 28%;
            transform: translateY(28%);    
        }

        &-2 {
            bottom: 0;
            right: 0;
            transform: translate(10%, -25%);    
        }
}
.web {
    .main-bl__heading {
        opacity: 0;
        transform: translateX(-3rem);
        transition: opacity .5s ease 0.1s, transform .5s ease 0.1s;
    }
    .main-bl__arrow {
        opacity: 0;
        transform: translateX(-4.5rem);
    }
    .main-bl-exellence {
        opacity: 0;
        transform: translateX(-4.5rem);
    }
    &.content-loaded .main-bl__picture {
        transition: opacity .5s ease 0.3s, transform .5s ease 0.3s;
    }
    &.content-loaded .main-bl__arrow {
        transition: opacity .5s ease, transform .5s ease;
    }
    &.content-loaded .main-bl-exellence {
        transition: opacity .7s ease, transform .7s ease;
    }
    &:not(.content-loaded) .main-text,
    &:not(.content-loaded) .main-bl__picture,
    &:not(.content-loaded) .main-arrow,
    &:not(.content-loaded) .main-bl-exellencem &:not(.content-loaded) .main-bl__heading {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
    &:not(.content-loaded) .main-bl__picture {
        opacity: 0;
        transform: translateX(3rem);
    }
    &:not(.content-loaded) .main-bl__heading {
        opacity: 0;
        transform: translateX(-3rem);
    }
    &:not(.content-loaded).main-arrow {
        opacity: 0;
        transform: translateX(4.5rem);
    }
    &:not(.content-loaded) .main-bl__arrow {
        opacity: 0;
        transform: translateX(-3rem);
    }
}

.slick-slide {
    .main-bl__heading {
        opacity: 0;
        transform: translateX(-3rem);
    }
    .main-bl__arrow {
        opacity: 0;
        transform: translateX(-4.5rem);
    }
    .main-bl-exellence {
        opacity: 0;
        transform: translateX(-4.5rem);
    }
}
.main-bl__heading {

    transition: opacity .8s ease, transform .8s ease;
}
.slick-current .main-bl__heading {
    transition: opacity .8s ease .2s, transform .8s ease .3s;
    opacity: 1;
    transform: translateX(0);
}



.catalog {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;

}
.gallery-wrap {
    overflow: auto
}
.section-main + .main-catalog  {

    margin-top: 70px;
}
.main-catalog {
    padding: 40px 0;
    overflow: hidden;
}

.catalog_main {
    display: block;
    margin: 0 -24px 0 0;
    .products-card {
        margin: 0 24px 0 0;
    }
}

.products-card {
    background: #F0F1F1;
    width: 168px;
    padding: 12px;
    margin: 0 12px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-shrink: 0;
    &__head {
        min-height: 82px;
    }
}
.slick-initialized .products-card {
    display: flex;
}
.products-card__title {
    font-size: 15px;
    line-height: 20px;
    font-family: $custom-font-family;
    a {
        color: $dark;
        &:hover {
            color: $color-active;
        }
    }
}

.products-card__img {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    picture {

        height: 190px;
        overflow: hidden;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2), 0px 6px 12px rgba(0, 46, 89, 0.12);
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
    img {
        max-height: 100%;
        vertical-align: top;
        transition: transform .4s ease
    }
    &:hover {
        transform: scale(1.05);
    }
}

.products-card__row {
    display: flex;
    align-items: center;
}

.buy-add__button {
    height: 40px;
    padding: 0 5px;
    line-height: 40px;
    width: calc(100% - 48px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    svg {
        margin-right: 8px;
        width: 25px;
        color: $color-active;
    }
}

.buy-add__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.products-card__favorite {
    margin-left: 8px;
    height: 40px;
    width: 40px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    transition: none;
    svg {
        width: 24px;
        color: $blue;
    }
    &.in-favorite {
        svg {
            color: $red;
        }
    }
}

.web .products-card__favorite {
    svg {
        transition: color .4s ease;
    }
    &:hover {
        svg {
            color: $color-active-2;
        }
    }
}

.products-card__author {
    margin-top: 5px;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: $text-color;
    a {
        color: inherit;
        transition: color .4s ease;
        &:hover {
            color: $color-active;
        }
    }
}

.products-card__row {
    margin-top: 12px
}
@keyframes bouncing-loader {
 0% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  21% {
    opacity: .4;
  }
  to {
    opacity: 0.4;
  }
}
.bouncing-loader-wrap {
       padding: 90px 0 20px;
       text-align: center;

}
.bouncing-loader {
      width: 39px;
    height: 39px;

  display: inline-flex;
  justify-content: center;
  flex-wrap: wrap;
 transform: rotate(45deg);
}
.bouncing-loader > div  {
    width: 50%;
    &:nth-child(even) {
        text-align: right;
    }
    span {
display: inline-block;
width: 16px;
height: 16px;

  background: $blue;
  border-radius: 50%;
  animation: bouncing-loader .6s infinite alternate;  
    }

&.el-green span {
    background: $color-active-2
}
}
.bouncing-loader > div:nth-child(2) {
  span {animation-delay: 0.25s;}
}
.bouncing-loader > div:nth-child(3) {

  span {animation-delay: 0.7s;}
}
.bouncing-loader > div:nth-child(4) {

  span {animation-delay: 0.5s;}
}

.main-catalog+.main-catalog {
    margin-top: 80px
}
@media screen and (max-width: 1366px) {
.section-main + .main-catalog {
    margin-top: 31px;
}
.main-catalog + .main-catalog {
    margin-top: 50px;
}
}
@media screen and (max-width: 1210px) {
.catalog_main  {
   .slick-list {
    overflow: visible;
   }
}
.main-bl__head {
    padding-left: 6%
}
.main-title {
    font-size: 51px;
    line-height: 54px;
    }
    .section-subtitle {
    margin: 10px 0 0;
    font-size: 22px;
    line-height: 27px;
}
}
@media screen and (max-width: 1199px) {
.catalog_main {
    margin: 0 24px 0 0;
    .products-card {
        margin: 0 24px 0 0;
    }
}
}
@media screen and (max-width: 1023px) {
.main-title {
    font-size: 47px;
    line-height: 49px;
}
    .section-subtitle {
    margin: 8px 0 0;
    font-size: 20px;
    line-height: 25px;
}
.slider-main__card {
    min-height: 300px;
}
}
@media screen and (max-width: 767px) {
    .slider-main {
        margin: 0 -24px
    }
    .main-bl__head {
        position: relative;
        z-index: 1
    }
.slider-main__card {
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
      picture  img {
            object-position: 50% 0;
        height: 28.5rem;
        }

}
.slick-initialized .slider-main__item {
    display: flex;
    flex: 1 1 100%;

}
    .main-catalog {
    padding: 20px 0;
}
.main-catalog_bg {
    padding: 40px 0;
}
    .main-catalog + .main-catalog {
    margin-top: 19px;
}
.main-catalog_bg + .main-catalog {
    margin-top: 41px;
}
.main-catalog + .main-catalog_bg {
    margin-top: 39px;
}
.catalog_main:not(.slick-initialized) {
    display: flex;
}
.main-bl__head {
    width: 100%;
    padding: 40px 24px 0;
    }
    .main-bl__picture {
        margin-top: -24px;
    width: 100%;
}
.main-title {
    font-size: 54px;
    line-height: 60px;
    }
    .section-subtitle {
    margin: 12px 0 0;
font-size: 18px;
line-height: 28px;
}
.section-main + .main-catalog {
    margin-top: 31px;
}
.decor {
    width: 48.88%
}
.decor-1 {
    top: 38%;
    bottom: auto;
    right: 0;
    transform: translate(21%, -50%);
}
.decor-2 {
    width: 88.38%;
    bottom: 0;
    left: 0;
    transform: translate(-35%, 9%);
}
}
@media screen and (max-width: 359px) {
.buy-add__button svg {
    margin-right: 3px;
    }
.main-title {
    font-size: 34px;
    line-height: 40px;
}
.slider-main__card picture img {

    height: 25.5rem;
}
}
@media screen and (min-width: 1200px) {
.bouncing-loader-wrap {
        padding: 164px 0 56px;
}
}