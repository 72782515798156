.main-wrapper {
	padding: 0;
	min-width: $min-width;
	width: 100%;
	position: relative;
	min-height: 100%;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	overflow: hidden;
	flex: 1 0 auto;
}



.wrapper {
	min-width: $min-width;
	max-width: $max-width;
	padding-right: 4rem;
	padding-left: 4rem;
	margin: 0 auto;
	position: relative;
	width: 100%;
	&-2 {
		max-width: 1520px
	}
}



/* titles */

p {
	padding: 1.2rem 0;
}
h1,
.h1 {
font-weight: bold;

	font-family: $custom-font-family;

	margin: 0 0 24px;
	color: $dark;
}
h1 {
	font-size: 40px;
line-height: 50px;
margin-bottom: 18px;
}
.h1 {

font-size: 24px;
line-height: 34px;
}
h2 {
	font-weight: bold;
font-size: 32px;
line-height: 42px;
	font-family: $custom-font-family;

	margin: 0 0 24px;
	text-align: left;
	color: $dark;
}
.h2 {
	font-weight: bold;
font-size: 40px;
line-height: 50px;
	font-family: $custom-font-family;

	margin: 0 0 24px;
	text-align: left;
	color: $dark;
	display: flex;
	align-items: center;
}

a.h2:hover {
	color: $dark;
.arrow-title {
	color: $color-active;
	transform: translateX(8px);
}
}
.h2-1 {
	.arrow-title {
	color: $color-active;
	}
}
a.h2-1:hover .arrow-title {
color: $color-active-2;
}
.arrow-title {
	margin-left: 12px;
	width: 40px;
height: 40px;
flex-shrink: 0;
background: rgba(0, 17, 24, 0.06);
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
color: $color-active-2;
	transition: color .3s ease, transform .4s ease;
svg {
    transition: color .4s ease;
	width: 24px;
}
}
.icon {
    display: inline-block;

		width: 24px;
		height: 24px;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
}

.h2-lg {
	font-size: 54px;
line-height: 60px;
letter-spacing: 0.01em;
}

h3,
.h3 {
	font-weight: bold;
	font-size: 2.4rem;
	line-height: 28px;
	color: $dark;
	padding-bottom: 2rem;
}

h4,
.h4 {
	font-weight: bold;
	font-size: 2.4rem;
	line-height: 28px;
	color: $dark;
}


/* text position */

.text-left {
	text-align: left!important
}

.text-center {
	text-align: center!important
}

.text-right {
	text-align: right!important
}

.nowrap {
	white-space: nowrap!important;
}

.center {
	text-align: center;
}

.pull-right {
	float: right;
}

.pull-left {
	float: left;
}

.hide {
	display: none !important
}


/*
================================================================================
|                                     CONTENT                                 |
================================================================================
*/

.content {
	min-width: $min-width;
	text-align: left;
	width: 100%;
	order: 2;
	flex-grow: 1;
	position: relative;
	padding: 10px 0 108px;
	z-index: 1;
}

.main-wrap {
	display: flex;
	flex-direction: column-reverse;
}

.content-wrap {
	background: #FFFFFF;
	padding: 33px 2.5rem 2.1rem;
	max-width: 119.6rem;
	margin: 0 auto
}

.more-text {
font-weight: 600;
font-size: 15px;
line-height: 20px;
	cursor: pointer;
	color: $color-active;
	text-align: right;
	margin-top: 23px;
	span {
		transition: color .3s ease-in
	}


}

.web .more-text:hover span {
	color: $dark;
}

.btn {
	color: $dark;
	font-family: $base-font-family;
	border: 0;
	font-weight: 500;
	font-size: 1.4rem;
	line-height: 5.6rem;
	height: 5.6rem;
	text-align: center;
	padding: 0 1.5rem;
	cursor: pointer;
	z-index: 0;
	background: $color-active-2;
	border-radius: .6rem;
	position: relative;
	text-align: center;
	display: inline-block;
	cursor: pointer;
	transition: background .3s ease-in, color .3s ease-in;
	&:hover {
	color: $dark;
	}	
}

.web {
	.btn:hover {
		background: #7BD2F8;
	}
}
	.btn:focus,
	.btn:active {
		background: #30B1E9;
	}

	.btn.active, .btn.active:hover,
	.btn:disabled, .btn:disabled:hover {
		color: rgba(0, 17, 24, 0.4);
		background: rgba(142, 216, 248, 0.4);
	}

.btn-2 {
	border: 2px solid $color-active-2;
	background: #fff;
	transition: background .3s ease-in, color .3s ease-in;

}

.btn-3 {
	background: linear-gradient(103.29deg, #B4DA4D 13.43%, #8ED8F8 52.29%, #B4DA4D 105.29%);
	background-size: 200% 100%;
	align-items: center;
	display: inline-flex;
	transition: background-position .3s ease-in, color .3s ease-in;
	justify-content: center;
	img {
		margin-left: 8px;
		height: 24px;
	}
}



.web {
	.btn-2:hover {
		background:$color-active-2;

	}
	.btn-3:hover {
		background: linear-gradient(103.29deg, #B4DA4D 13.43%, #8ED8F8 52.29%, #B4DA4D 105.29%);
	background-size: 200% 100%;
		background-position: 200% 0
	}
}

input[type="text"], input[type="search"], input[type="tel"], input[type="email"], textarea {
	border-radius: 6px;
	color: $dark;
	padding-left: 1.2rem;
	padding-right: 1.2rem;
	font-size: 1.6rem;
	font-family: $base-font-family;
	width: 100%;
	height: 56px;

	border: 2px solid #f0f1f1;
	font-weight: 500;
	font-size: 1.4rem;
	background: #f0f1f1;
	transition: border .3s ease-in, background .3s ease-in;
	&:hover {
		background: #e6e8e8;
		border-color:  #e6e8e8;
	}
	&:focus {
		border-color: $color-active;
		background: #fff
	}
	&.error {
		border-color: red
	}
}

::-webkit-input-placeholder {
	color: $placeholder
}

::-moz-placeholder {
	color: $placeholder
}

:-moz-placeholder {
	color: $placeholder
}

:-ms-input-placeholder {
	color: $placeholder
}

textarea.form-control {
	height: 10rem;
	resize: none;
	line-height: 2.1rem;
	padding-top: 1rem;
	padding-bottom: 1.2rem;
	vertical-align: top;
	resize: vertical;
}

.form-control-2 {
	color: $dark;
	padding-left: 1.2rem;
	padding-right: 1.2rem;
	font-size: 1.6rem;
	font-family: $base-font-family;
	width: 100%;
	height: 5rem;
	font-weight: 500;
	font-size: 1.4rem;
	border: 1px solid rgba(19, 19, 19, 0.2);
	border-radius: 10px;
	transition: border .3s ease-in;
	&:focus {
		border-color: $color2;
	}
	&.error {
		border-color: red
	}
}




.box-field {
	margin-bottom: 1.4rem;

}

.btn_submit {
	width: 100%
}

.box-field__label {
font-weight: 500;
font-size: 14px;
line-height: 20px;
	margin-bottom: 7px;
	sup {
		vertical-align: top;
		color: $red
	}
}


.label-placeholder {
	color: rgba(37, 35, 35, .6);
	text-align: left;
	padding-left: 0;
	top: .6rem;
	display: block;
	position: absolute;
	left: 0;
	font-size: 1.4rem;
	font-weight: 500;
}

label.error {
	color: red;
	font-size: 13px;
	font-weight: 500;
	margin: 8px 0 0 1.5rem
}

.box-field__input {
	position: relative;
}




.label-placeholder {
	letter-spacing: 0.02em;
	position: absolute;
	left: 0;
	text-align: left;
	padding-left: 0;
	top: .7rem;
	font-weight: 400;
	display: block;
	position: absolute;
	font-size: 1.6rem;
	line-height: 2.1rem;
	color: rgba(#FFF, .8);
	pointer-events: none
}

.filled {
	.label-placeholder {
		font-size: 80%;
		top: -18px;
		opacity: 0
	}
}



.checkbox-element {
	cursor: pointer;
	position: relative;
	display: inline-flex;
	input {
		flex-shrink: 0;
		display: inline-block;
		margin-top: 0;
		position: absolute;
		left: 0;
		top: 0;
		&:checked {
			&~.check {
				border-color: transparent;
				&:after {
					opacity: 1;
				}
			}
		}
		;
	}
}



.checkbox-element {
	padding-left: 35px;
	display: inline-flex;
	min-height: 20px;
	position: relative;
	z-index: 0;
	&:hover~ .checkbox-text .check {
		border-color: $color-active;
	}
	input[type="checkbox"]:checked ~ .checkbox-text .check,
	input[type="radio"]:checked ~ .checkbox-text .check{
		background-color: $blue;
		background-image: url(../img/icons-svg/check.svg);
		background-repeat: no-repeat;
		background-size: 13px 11px;
		background-position: 50% 50%;
		transition: background-color 0.3s ease-in, border 0.3s ease-in;
	}
}

	.check {
		background: transparent no-repeat center;
		background-size: 1.1rem 1.1rem;
		border: 2px solid $dark;
		width: 22px;
height: 22px;
display: inline-block;
		padding: 0;
		flex-shrink: 0;
		background: none;
		position: relative;
		border-radius: 0;
		margin: 1px 0 0;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 0;
	}
.checkbox-element input {
	opacity: 0;
	position: absolute;
}

.checkbox-text {
font-weight: 500;
font-size: 12px;
line-height: 18px;
	color: $dark;
	cursor: pointer;
}


.hide {
	display: none;
}

.color-decor {
	color: $color1;
}

.tabs-nav-wrap {
	overflow: auto;

}

.tabs-nav {
	&__link {
		position: relative;
		display: inline-block;
		font-weight: 600;
		font-size: 1.4rem;
		line-height: 120%;
		padding-bottom: 13px;
		height: 3.4rem;
		line-height: 3.4rem;
		cursor: pointer;
		border-radius: 2rem;
		padding: 0 1.6rem;
		white-space: nowrap;
		transition: color .4s ease, background .4s ease;
		span {
			color: rgba($dark, .6)
		}
		&:last-child {
			margin-right: 0
		}
		&.active,
		&:hover {
			background: #EBE0D3;
			color: $color-active
		}
		&.active {
			cursor: default
		}
	}
}

.web .tabs-nav__link:hover:before {
	transform: scale(1)
}

.tabs-wrap {
	margin-top: 3.8rem;
	position: relative
}

.tab {
	position: absolute;
	z-index: -1;
	left: 0;
	top: 0;
	right: 0;
	visibility: hidden;
	&.active {
		position: static;
		visibility: visible
	}
}
.arrow-scroll-top {
  position: fixed;
  bottom: 24px;
  right: 5.6%;
  z-index: 210;
  transform: scale(0);
  will-change: transform;
  transition: all .3s linear;
  &.shown {
    transform: scale(1);
  }
}
@keyframes fadeToggle {
  from {
    opacity: 0
  }
  49.99% {
    opacity:0
  }
  50% {
    opacity:1
  }
  to {
    opacity: 1
  }
}
@keyframes fadeToggle2 {
  from {
    opacity: 1
  }
  49.99% {
    opacity:1
  }
  50% {
    opacity:0
  }
  to {
    opacity: 0
  }
}
.button-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: rgba(0, 17, 24, 0.06);
width: 56px;
color: $dark;
font-size: 23px;
height: 56px;
  box-shadow: 0 2px 6px 0 rgba(0, 1, 1, 0);
  border-radius: 50%;
  cursor: pointer;
  transition: box-shadow .3s ease;

}

.web .button-arrow:hover {
  box-shadow: 0 1px 5px 0 rgba(0, 1, 1, .5)
}
.scrollDisabled {
	overflow: hidden;
	position: fixed !important;
	margin-top: 0;
	width: 100%;
	z-index: 0;
}

.scrollDisabled.web,
.scrollDisabled.web.compensate-for-scrollbar,
.scrollDisabled.web .header {
	padding-right: 1.7rem
}

.scrollDisabled .main-wrapper {
	overflow: visible;
}

@media screen and (min-width: 768px) {
	.mob-show {
		display: none !important;
	}
}

@media screen and (min-width: 1024px) {
	.tablet-show {
		display: none !important;
	}
}
@media screen and (max-width: 1439px) {
	.arrow-scroll-top {
		right: 3px;
	}
}
@media screen and (max-width: 1100px) {
	.wrapper {
		padding-right: 3rem;
    padding-left: 3rem;
	}
}
@media screen and (max-width: 1023px) {

	.wrapper {
		padding-left: 2.4rem;
		padding-right: 2.4rem;
	}

	.content {
		padding-bottom: 90px
	}
	.tablet-hide {
		display: none !important
	}
	.h2-lg {
		font-size: 45px;
		line-height: 50px
	}
}

@media screen and (max-width: 767px) {


	.content {
		padding-bottom: 100px
	}
	.mob-hide {
		display: none !important;
	}

	.tab {
		padding-left: 0
	}
	.h2-lg {
		font-size: 40px;
line-height: 50px;
	}
	.more-text {
    margin-top: 11px;
}
.arrow-scroll-top {
	display: none;
}
}


@media screen and (max-width: 359px) {
	.wrapper {
    padding-left: 12px;
    padding-right: 12px
}
	.sm-mob-hide {
		display: none !important
	}
}
@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
	.text-gradient,
	.arrow-down i.text-gradient {
		background: none;
		color: #41FF6B
	}
}