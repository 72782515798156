@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Regular/Roboto-Regular.eot');
  src: url('../fonts/Roboto-Regular/Roboto-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto-Regular/Roboto-Regular.woff') format('woff'), url('../fonts/Roboto-Regular/Roboto-Regular.ttf') format('truetype'), url('../fonts/Roboto-Regular/Roboto-Regular.svg#Roboto-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Regular-Italic/RobotoItalic.eot');
  src: url('../fonts//Roboto-Regular-Italic/RobotoItalic.eot?#iefix') format('embedded-opentype'), url('../fonts//Roboto-Regular-Italic/RobotoItalic.woff') format('woff'), url('../fonts//Roboto-Regular-Italic/RobotoItalic.ttf') format('truetype'), url('../fonts//Roboto-Regular-Italic/RobotoItalic.svg#RobotoItalic') format('svg');
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Medium/Roboto-Medium.eot');
  src: url('../fonts/Roboto-Medium/Roboto-Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto-Medium/Roboto-Medium.woff') format('woff'), url('../fonts/Roboto-Medium/Roboto-Medium.ttf') format('truetype'), url('../fonts/Roboto-Medium/Roboto-Medium.svg#Roboto-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Bold/Roboto-Bold.eot');
  src: url('../fonts/Roboto-Bold/Roboto-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto-Bold/Roboto-Bold.woff') format('woff'), url('../fonts/Roboto-Bold/Roboto-Bold.ttf') format('truetype'), url('../fonts/Roboto-Bold/Roboto-Bold.svg#Roboto-Bold/Roboto-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: url('../fonts/RobotoCondensedRegular/RobotoCondensedRegular.eot');
  src: url('../fonts/RobotoCondensedRegular/RobotoCondensedRegular.eot?#iefix') format('embedded-opentype'), url('../fonts/RobotoCondensedRegular/RobotoCondensedRegular.woff') format('woff'), url('../fonts/RobotoCondensedRegular/RobotoCondensedRegular.ttf') format('truetype'), url('../fonts/RobotoCondensedRegular/RobotoCondensedRegular.svg#RobotoCondensedRegular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto Condensed';
  src: url('../fonts/RobotoCondensedBold/RobotoCondensedBold.eot');
  src: url('../fonts/RobotoCondensedBold/RobotoCondensedBold.eot?#iefix') format('embedded-opentype'), url('../fonts/RobotoCondensedBold/RobotoCondensedBold.woff') format('woff'), url('../fonts/RobotoCondensedBold/RobotoCondensedBold.ttf') format('truetype'), url('../fonts/RobotoCondensedBold/RobotoCondensedBold.svg#RobotoCondensedBold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon/fonts/icomoon.eot');
    src: url('../fonts/icomoon/fonts/icomoon.eot?#iefix') format('embedded-opentype'),
        url('../fonts/icomoon/fonts/icomoon.woff') format('woff'),
        url('../fonts/icomoon/fonts/icomoon.svg#icomoon') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}






[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

