/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: flex;
    margin-left: auto;
    margin-right: auto;



    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        vertical-align: top
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow {
    background: transparent;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 50%;
    z-index: 10;

    padding: 0;
    position: absolute;
    top: 50%;
    color: #000;
    z-index: 2;
width: 5rem;
height: 5rem;
        color: $dark;

background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:1.6rem;
    left: -5px;
    top: 50%;
    margin-top: -2.5rem;

    transition: color .6s ease-out, border-color .6s ease-out;

    &.slick-next  {

    left: auto;
        right: -5px;

    }
}


.slick-prev {
    text-align: right;
}
.slick-next {
    text-align: left;
}
.web .slick-arrow:hover {
    border-color: rgba(125, 116, 228, 0.3);
    color: $color2
}

.slick-arrow.slick-hidden {
    display: none;
}
.slick-dots {
    list-style: none;
    margin-top:12px;
    text-align: center;
    li {
        display: inline-block;
        margin: 0 6px;
        cursor: pointer
    }
    button {
        text-indent: -99999px;
        font-size: 0;
        border: 0;
        vertical-align: top;
        cursor: pointer;
        padding: 0;
        width: 12px;
height: 12px;
border-radius: 50%;

background: #F0F1F1;

    }

        .slick-active {
            button {
                background: $color-active-2
            }
        }
} 
.slick-disabled, .slick-disabled:hover {
    opacity: 0.3;
    cursor: default;

}

