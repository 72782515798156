/* reset */
$min-width: 320px; 
$max-width: 1208px; 
$base-font-size: 18px; 
$base-line-height: 1.55; 
$base-font-family: Roboto, arial, sans-serif; 
$custom-font-family: 'Roboto Condensed', arial, sans-serif; 
$default-transition:all 0.3s ease-in;
/* main variables */

//text, titles
$text-color:rgba(0, 17, 24, 0.6) ;
$dark: #001118;
$title-color: $text-color;
$title-font-family: $base-font-family;
$color-active:#30B1E9;
$color-active-2:#B4DA4D;
$blue: #8ED8F8;
$red: #FF6948;
$bg-color:#fff;
$bg2: #FDD2BC;
$color1: rgba(#0090F0, .5);
$color2: #7D74E4;
$color3: #004A9E;
$link-color: $color-active;
$link-color-hover: $color-active-2;
$base-bg: #fafafa; 
$placeholder:rgba($text-color, .4 );

$font-size-h1: 36px;
$line-height-h1: 42px;
$font-size-h2: 32px;
$line-height-h2: 37px;
$font-size-h3: 28px;
$line-height-h3: 32px;
