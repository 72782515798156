.header {
    z-index: 10;
    background: #fff;
    flex-shrink: 0;
    transition: box-shadow .3s ease-in;
    .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:after {
            display: none;
        }
    }
/*    &.fixed {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
        .header-wrapper_bottom {
            height: 0
        }
        .menu-catalog {
            top: 15px;
        }
        .menu-box {
            display: none;
        }
    }*/
}

.header-wrapper_top {
    padding: 11px 0 10px;
}

.header-wrapper_bottom {
    margin-top: 38px;
    .wrapper {
        flex-wrap: wrap
    }
}

.header-phone {
    font-size: 12px;
    line-height: 18px;
    margin-right: 24px;
    a {
        display: inline-flex;
        align-items: center;
    }
}
.fixed  {
    .header-navs-wrap {
    background: #fff;
    position: fixed;
    box-shadow: 0 5px 5px rgba(0,0,0,.1);
    left: 0;
    top: 0;
    right: 0;
    z-index: 100
}
.nav-logo .logo  {

    top: 0;
}
.nav-logo .logo  img{
    max-height: 68px;
    width: auto;
}
}
.shops-link {
    margin-right: 24px;
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 17, 24, 0.6);
    &:hover {
        color: $color-active;
    }
}

.nav-inner {
    flex-shrink: 10;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.header-info {
    padding-left: 15px;
    width: calc(100% - 230px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0
}

.header-cart,
.header-favorite {
    background: rgba(0, 17, 24, 0.06);
    border-radius: 50%;
    width: 5.6rem;
    height: 5.6rem;
    position: relative;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: background .4s ease;
    svg {
        width: 2.4rem
    }
    &:hover {
        background: rgba($color-active, 0.16);
    }
}

.header-cart {
    position: relative;
    margin: 0 39px 0 0;
    &__badge {
        color: #fff;
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        right: 1px;
        top: -1px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $red;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        padding-top: 1px;
        transition: transform .3s ease
    }
    &__btns {
        margin-top: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__row {
        display: flex;
        align-items: center;
        justify-content: flex-end
    }
}

.header-favorite {
    margin-right: 24px;
    svg {
        color: $red;
    }
}

.header-login {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $dark;
    display: inline-flex;
    align-items: center;
    svg {
        width: 24px;
        height: 24px
    }
    span {
        display: inline-block;
        margin: 2px 8px 0 0;
    }
}

.menu-box {
    margin-top: 22px;
    width: 100%
}

.nav-logo {
    .logo {
        top: -13px;
        position: relative;
        z-index: 0
    }
}

.logo {
    display: block;
    width: 148px;
}

.bars-mob {
    width: 50px;
    height: 50px;
    padding: 5px;
    margin: 0 25px 0 -15px;
    display: none;
    position: relative;
    cursor: pointer;
}

.bars-mob .hamburger {
    position: absolute;
    height: 2.3rem;
    width: 2rem;
    margin-top: 1.5rem;
    margin-left: 1rem;
}

.bars-mob .hamburger span {
    display: block;
    width: 100%;
    height: .2rem;
    position: relative;
    background: #131313;
    top: 0;
    left: 0;
    margin: 0 0 .4rem;
    transition: .25s ease-in-out;
}

.bars-mob .hamburger span:nth-child(1) {
    transition-delay: .5s;
}

.bars-mob .hamburger span:nth-child(2) {
    transition-delay: .625s;
}

.bars-mob .hamburger span:nth-child(3) {
    transition-delay: .75s;
}

.bars-mob .cross {
    position: absolute;
    height: 19px;
    width: 19px;
    margin: 9px 0 0 10px;
    transform-origin: center center;
    transform: rotate(45deg);
}

.bars-mob .cross span {
    display: block;
    background: #131313;
    transition: .25s ease-in-out;
}

.bars-mob .cross span:nth-child(1) {
    height: 0%;
    width: .2rem;
    position: absolute;
    left: 11px;
    top: 2%;
    transition-delay: 0s;
}

.bars-mob .cross span:nth-child(2) {
    width: 0%;
    height: .2rem;
    position: absolute;
    left: 15%;
    top: 8px;
    transition-delay: .25s;
}

.bars-mob.active .hamburger span {
    width: 0%;
}

.bars-mob.active .hamburger span:nth-child(1) {
    transition-delay: 0s;
}

.bars-mob.active .hamburger span:nth-child(2) {
    transition-delay: .125s;
}

.bars-mob.active .hamburger span:nth-child(3) {
    transition-delay: .25s;
}

.bars-mob.active .cross span:nth-child(1) {
    height: 100%;
    transition-delay: .625s;
}

.bars-mob.active .cross span:nth-child(2) {
    width: 100%;
    transition-delay: .375s;
}

.bars-mob,
.bars-mob * {
    cursor: pointer;
}

.header-contacts {
    display: flex;
    align-items: center;
}

.header-contacts_mob {
    display: none;
}

.loaded .menu-box {
    transition: none
}

.messangers {
    display: inline-flex;
    align-items: center;
    img {
        max-width: 20px;
        max-height: 20px;
        opacity: .6;
        transition: opacity .4s ease
    }
    &__link {
        margin: 0 12px;
        transition: none;
        &:hover img {
            opacity: 1;
        }
    }
}

.header .messangers {
    position: relative;
    top: 2px;
    margin-right: 13px
}

.open-header {
    background: #ccc;
    .header {
        z-index: 1000
    }
    .content,
    footer {
        opacity: 0.7
    }
}

.menu-catalog,
.menu-mob {
    list-style: none;
    display: flex;
    li {
        margin-right: 1.5rem;
        &:hover {
            ul {
                opacity: 1;
                transform: translateY(0);
                visibility: visible;
            }
            & >a {
                color: $color-active;
            }
        }
    }
    a {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: $dark;
    }
}

.menu-catalog {
    padding: 0 0 3px 5px;
    position: relative;
    li {
        margin: 0 28px 0 0;
    }
    ul {
        background: #F0F1F1;
        border-radius: .6rem;
        position: absolute;
        list-style: none;
        padding: 23px 1px;
        left: 0;
        margin-top: -2px;
        top: 100%;
        width: 1128px;
        z-index: 100;
        opacity: 0;
        transform: translateY(1.5rem);
        visibility: hidden;
        transition: all .5s ease;
        display: flex;
        &>li {
            flex-shrink: 0;
            &:last-child {
                width: 100%;
                flex-shrink: 10;
            }
        }
        li {
        	margin: 0
        }
        li {
            li {margin: 0 45px 10px 0;}
            
        }
        &.cat-1 {
        	li {
        		margin-right: 0
        	}
        }
        ul {
            padding: 0 22px;
            position: static;
            display: inline-grid;
            width: auto;
            grid-template-rows: repeat(4, auto);
            grid-auto-flow: column;
    
        }
        a {
            padding: 0;
            font-size: 14px;
            white-space: nowrap;
            &:hover {
                background: #F6F2EC;
                color: $color-active;
            }
        }
    }
    >li {
        padding: 11px 0;
        >a {
        	padding: 3px 0;
        	position: relative;
        	&:before {
        		content: '';
        		display: block;
        		right: -4px;
        		bottom: -3px;
        		position: absolute;
        		height: 4px;
        		left: -4px;
        		opacity: 0;
        		transition: opacity .4s ease;
        		background:  #B3DD41
        	}
        }
        > a:hover {
        	color: $dark;
        	&:before {
        	opacity: 1
        	}
        }
    }
}
.cat-title {
	font-size: 12px;color: rgba(0, 17, 24, 0.4);
}
.menu-mob {
    display: none;
}

.arr-menu {
    font-size: .7rem;
    color: $color-active;
    margin: -.2rem 0 0 .5rem;
    padding: .3rem;
    display: inline-block;
    cursor: pointer;
    transition: transform .4s ease
}

.open-filters {
    .content {
        position: relative;
        z-index: 11110;
    }
}

.header-navs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-navs__left {
    display: flex;
    align-items: center;
    padding-right: 30px;
    width: calc(100% - 250px);
    max-width: 800px;
}

.header-navs__right {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.main-menu {
    padding: 9px 15px 11px;
    background: rgba(0, 17, 24, 0.06);
    list-style: none;
    display: flex;
    width: 100%;
    li {
        list-style: none;
        margin: 0 30px 0 0;
        &:last-child {
            margin-right: 0;
        }
    }
    .current-menu-item a {
        cursor: default
    }
    a {
        font-size: 12px;
        line-height: 18px;
        color: $dark;
        transition: color .3s ease;
        &:hover {
            color: $color-active;
        }
        ;
    }
}

.web .main-menu a:hover,
.main-menu .current-menu-item a {
    color: $color-active;
}

.header-town {
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    width: 230px;
    &__val {
        color: $color-active;
        cursor: pointer;
        margin-left: 3px;
        transition: color .4s ease;
        &:hover {
            color: $color-active-2;
        }
    }
    &__label {

        flex-shrink: 0;
    }
}

.search-block {
    max-width: 552px;
    width: calc(100% - 194px);
    margin-left: 44px;
    position: relative;
    &__form {
        position: relative;
    }
    input[type="text"],
    input[type="search"] {
        padding-right: 62px;
        &.filled {
            & ~ .close-popup-search {
                display: flex;
                opacity: 1
            }
            &~ [type="submit"] {
                opacity: 0;
                display: block;
            }
            &~ .close-popup-search {border-left-color: rgba(0, 17, 24, 0.06)}
        }
    }
    [type="submit"] {
        border: 0;
        height: 52px;
        line-height: 20px;
        padding: 7px 2px;
        width: 45px;
        background: none;
        line-height: 52px;
        text-align: center;
        display: inline-block;
        position: absolute;
        right: 2px;
        top: 2px;
        svg {
            width: 24px;
            height: 24px;
            color: $color-active-2;
            transition: color .4s ease;
        }
        &:hover svg {
            color: $color-active;
        }
        &:before {
            content: '';
            display: block;
            width: 2px;
            background: rgba(0, 17, 24, 0.06);
            top: 8px;
            bottom: 8px;
            left: -3px;
            position: absolute;
        }
    }
    &.active {
        .close-popup-search {
            display: flex;
            opacity: 0;
        }
        .close-popup-search {
        	opacity: 1;
        }
        [type="submit"] {
        	opacity: 0
        }
    }
}

.search-dropdown {
	padding: 2px 0 0;
    background: #FFFFFF;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 100;
    display: none;
    box-shadow: 4px 4px 14px 2px rgba(0, 46, 89, 0.15);
    border-radius: 6px;
    color: $dark;
    font-size: 14px;
    line-height: 20px;
    ul {
        list-style: none;
        li:first-child a {
            border-radius: 6px 6px 0 0;
        }
        li:last-child a {
            border-radius: 0 0 6px 6px;
        }
    }
    a {
        padding: 12px;
        color: inherit;
        display: block;
        transition: background .4s ease, font-weight .4s ease;
        &:hover {
            color: inherit;
            font-weight: 500;
            background: rgba(240, 241, 241, 0.8);
        }
    }
}

.close-popup-search, .clear-popup-search, .ms-choice > div.icon-close {
    position: absolute;
    right: 2px;
    width: 48px;
    top: 10px;
    bottom: 10px;
    color: $dark;
    border-left: 2px solid #8ED8F8;
    cursor: pointer;
    display: none;
    justify-content: center;
    align-items: center;
    opacity: 0;
    &:hover {
        color: $color-active;
    }
}

.header-navs__menu {
    margin-top: 13px;
    display: flex;
    align-items: center;
}

.header-categories {
    display: flex;
    align-items: center;
    list-style: none;
    li {
        margin-right: 24px;
    }
    a {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        border-radius: 6px;
        display: inline-block;
        padding: 4px 12px;
        background: rgba($color-active-2, .6);
        transition: none;
        color: $dark;
        &:hover {
            color: $dark;
        }
        &:focus,
        &:active,
        &active {
            background: #7BD2F8;
        }
    }
}

.web .header-categories a {
    transition: background .4s ease;
    &:hover {
        background: #7BD2F8;
    }
}

.header-language {
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 17, 24, 0.6);
    padding-right: 6px;
    display: flex;
    align-items: center;
    img {
        height: 16px;
        vertical-align: middle;
    }
    &__title {
        padding-left: 9px;
    }
    &:hover {
        color: $color-active;
    }
}

.header-search-icon {
    margin-right: 24px;
    background: rgba(0, 17, 24, 0.06);
    border-radius: 50%;
    width: 5.6rem;
    height: 5.6rem;
    position: relative;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: background .4s ease;
    svg {
        color: $color-active-2;
    }
    &:hover {
        background: rgba($color-active, 0.16);
    }
}
.button-login {
	border-bottom: 2px solid rgba(0, 17, 24, 0.06);
    padding-bottom: 12px;
    margin-bottom: 9px;
}
.btn_login {
    width: 100%;
    height: 40px;
    line-height: 39px;
    flex-shrink: 0
}

.header-links {
    color: $dark;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    order: 2;
    a {
    	color: inherit;
    }
    &__item {
    	margin-bottom: 4px;
    }
    &__icon {
    	padding: 2px 0 0 2px;
        position: relative;
        display: inline-block;
        width: 26px;
        margin-right: 3px;
        vertical-align: middle;
    }
    &__link {
        color: $dark;
    }
    .icon {
        color: $color-active-2;
    }
    .icon-favorite {
        color: $red;
    }
    &__text {
    	margin-left: 5px;
    	span {
    	color: $text-color;}
    }
}
.filters-opened {
    .header {z-index: 0}
}
@media screen and (max-width: 1207px) {
    .menu-catalog ul {
        width: calc(100vw - 80px)
    }
}
@media screen and (max-width: 1200px) {
.header-categories a {
	font-size: 14px;
	padding: 5px 10px
}
	.menu-catalog {
		padding-left:0
	}
	.header-categories li {
    margin-right: 17px;
}
.header-language {
	padding-right: 0
}
}
@media screen and (max-width: 1100px) {
.header-wrapper_bottom {
    margin-top: 29px;
}
.menu-catalog a {
	font-size: 14px
}
.menu-catalog > li {
	margin-right: 20px;
}.header-categories a {
	padding: 5px 9px
}
}
@media screen and (max-width: 1023px) {
    .bars-mob,
    .menu-mob {
        display: block
    }
    .bars-mob {
        flex-shrink: 0
    }
    .menu-box {
        position: fixed;
        left: -100%;
        background: #F0F1F1;
        bottom: 0;
        top: 98px;
        width: 360px;
        max-width: 100%;
        margin: 0;
        display: block;
        padding: 24px 24px 15px;
        z-index: 100;
        overflow: auto;
        transition: left .5s ease;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        whill-change: left;
        &.active {
            left: 0;
        }
    }
    .search-block {
        display: none;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        margin: 0;
        background: #fff;
        max-width: 100vw;
        width: 100vw;
        bottom: 0;
        z-index: 101;
        &.shown {
        	display: block;
        }
    }
    .close-popup-search {
    	display: flex;
    	opacity: 1;
    	border-left-color: transparent;
    }
    .search-block input[type="text"], .search-block input[type="search"] {

    	    padding-left: 60px;
    }
    .search-block input[type="text"]:focus, .search-block input[type="search"]:focus {
    	    border-color: #f0f1f1;
    	    background: #f0f1f1;
    }
    .search-block [type="submit"] {
    	left: 0;
    	width: 56px;
    	padding-left: 17px;
    }
    .search-block [type="submit"] svg {
    	color: $blue;
    }
    .search-block.active [type="submit"] {
    	opacity: 1
    }
    .search-dropdown {
    	position: static;
    	box-shadow: none;
    	border-radius: 0
    }
    .btn_login {
        order: 1;
    }
    .header-navs__menu {
        display: block;
    	    margin-top: 3px;
    	    padding-top: 12px;
    	border-top: 2px solid rgba(0, 17, 24, 0.06);
        order: 2;
    }
    .nav-inner {
    	    padding-top: 10px;
    	    flex-shrink: 0;
        order: 3;
    	border-top: 2px solid rgba(0, 17, 24, 0.06);
    }

    .main-menu li {
        margin: 0 0 9px;
    }
    .menu-catalog {
        display: block;
        padding: 0
    }
    .main-menu {
        background: none;
        padding: 0;
        display: block;
    }

    .header-categories {
    	border-top: 2px solid rgba(0, 17, 24, 0.06);
        margin: 0 -12px 0 0;
        padding-top: 11px;
        flex-wrap: wrap;
        li {

        margin: 0 12px 12px 0;
        }
    }
    .header-categories a {
            padding: 12px 14px;
    }
    .menu-catalog ul {
    	margin-top: 7px;
    	padding: 0;
        position: static;
        width: 100%;
        display: block;
        opacity: 1;
        visibility: visible;
        transform: none
    }
    .menu-catalog > li {
    margin-right: 0;
    padding: 0;
    margin-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        > span {
font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #001118;
    width: calc(100% - 30px)
        }
}

.menu-catalog ul ul li {
    margin: 0 0 15px;
}
    .menu-catalog ul ul {
    padding: 0 13px;
        display: none
    }

.menu-catalog ul > li:last-child {
	margin-bottom: 1px
}

    .menu-catalog .active {
        ul {
            display: block
        }
        i.js-arr-menu {
            transform: rotateX(180deg);
        }
    }
    .menu-catalog i.js-arr-menu {
        display: inline-flex;
        height: 24px;
        width: 24px;
        margin: -1px -1px 0 0;
        transition: transform .5s ease
    }
    body:not(.loaded) .menu-catalog i.js-arr-menu {
        background: url(../img/icons-svg/down.svg) 50% 50% no-repeat;
        background-size: contain;
    }
    .overlay {
        position: fixed;
        z-index: 99;
        background: rgba(0, 0, 0, .6);
        left: 0;
        right: 0;
        bottom: 0;
        top: 98px;
        visibility: hidden;
        opacity: 0;
        transition: opacity .4s ease
    }
    .open-header .overlay {
        visibility: visible;
        opacity: 1
    }
    .logo {
    display: block;
    width: 138px;
}
.header-town__val {
	max-width: 200px;
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	vertical-align: middle;
}
.logo {
    width: 132px;
}
}

@media screen and (max-width: 767px) {
    .header-contacts .messangers,
    .header-phone {
        display: none;
    }
    .header-cart,
    .header-favorite,
    .header-search-icon {
        margin: 0 2px 0 0;
        width: 38px;
        height: 38px;
        background: none
    }
    .logo img {
        height: 40px
    }
    .header-cart__badge {
        width: 18px;
        height: 18px;
        font-size: 14px;
        line-height: 18px;
            right: -5px;
    top: -2px;
    }
    .header-wrapper_top {
    padding: 3px 0 6px;
}
.header-login {
    margin-left: 20px
}
.bars-mob {
	margin-right: 1px;
}
.bars-mob .hamburger {
	margin:13px 0 0 12px;
}
.bars-mob .cross {
	margin: 9px 0 0 10px;
}
.header-wrapper_bottom {
    margin-top: 21px;
}
.nav-logo {
    .logo {
        top: -5px;
    }
}
    .header-cart {
    	margin-right: -7px;
    }
    .header-navs__left {
        width: calc(100% - 180px)
    }  
}

@media screen and (max-width: 479px) {
    .shops-link,
    .header-login span,
    .header-login {
        display: none;
    }
    .logo {
    width: 102px;
}
}

@media screen and (max-width: 359px) {.bars-mob {
        margin-left: -12px
    }
    .header-cart {
    margin-right: -1px;
}
}
@media screen and (min-width: 480px) {
    .button-login {
        display: none;
    }
}

@media screen and (min-width: 1024px) {
    .header-search-icon {
        display: none;
    }
}

